$.fn.imageSize = function (options) {

    return $(this).each(function () {
        options = $.extend({
            width: 1, height: 1, animateOpacity: false
        }, options);
        function onLoad() {
            var a1 = this.naturalWidth / this.naturalHeight;
            var a2 = 1;
            if (options.height > 0) {
                a2 = options.width / options.height;
            }
            if (a1 > a2) {
                $(this).addClass("fit-switch");
            }
            if (options.animateOpacity) {
                $(this).animate({opacity: 1}, 200);
            }
        }
        if ($(this).hasClass("show")){
            if ($(this).is(":visible")){
                $(this).on("load", onLoad).attr("src", $(this).data("src")).removeClass("show");
            } else {
                function show(){
                    $(this).on("load", onLoad).attr("src", $(this).data("src")).removeClass("show");
                    $(this).off("show", show);
                }
                $(this).on("show", show);
            }
        } else {
            if (this.naturalWidth > 0) {
                $(this).removeClass("fit-switch");
                onLoad.call(this);
            } else {
                $(this).on("load", onLoad);
            }
        }


    });
};

