//mode 1 = "horizontal", mode 2 = "vertical"
function ScrollableFilmstrip(elm, options) {
    var $elm = $(elm), prop = $elm.hasClass("vertical") ? {"v": "top", "t": "height", c: "y"} : {
            "v": "left",
            "t": "width",
            c: "x"
        };
    options = $.extend({
        mode: 1,
        clickMove: 100,
        interval: 10,
        move: 5,
        hover: true,
        boxScroll: true,
        endless: !!$(elm).data("endless")
    }, options);
    var i, func, max, timer, boxes = $elm.find(".ui-filmstrip-box").show(),
        left = $elm.find(".ui-filmstrip-prev"), right = $elm.find(".ui-filmstrip-next"), boxesImg = boxes.find("img");

    function positionBoxes(number, anim) {
        anim = anim || false;
        if (boxes.length == 0) {
            return;
        }
        var cWidth = boxes.eq(0).position()[prop.v] + number;
        for (i = 0, max = boxes.length; i < max; i++) {
            var css = {};
            css[prop.v] = cWidth;
            if (anim) {
                TweenLite.killTweensOf(boxes.eq(i));
                TweenLite.to(boxes.eq(i), 0.5, {
                    css: css, ease: Power2.easeIn, onComplete: function () {
                    }
                });
            } else {
                boxes.eq(i).css(css);
            }
            cWidth += boxes.eq(i)[prop.t]();
        }
        if (anim) {
            setTimeout(function () {
                $elm.removeClass("status-start");
                $elm.removeClass("status-end");
                if (boxes.eq(0).position()[prop.v] == 0) {
                    $elm.addClass("status-start");
                }
                if (boxes.eq(boxes.length - 1).position()[prop.v] + boxes.eq(boxes.length - 1)[prop.t]() == $(elm)[prop.t]()) {
                    $elm.addClass("status-end");
                }
                $elm.trigger("filmstrip.moved");
            }, 600);
        } else {
            $elm.removeClass("status-start");
            $elm.removeClass("status-end");
            if (boxes.eq(0).position()[prop.v] == 0) {
                $elm.addClass("status-start");
            }
            if (boxes.eq(boxes.length - 1).position()[prop.v] + boxes.eq(boxes.length - 1)[prop.t]() == $(elm)[prop.t]()) {
                $elm.addClass("status-end");
            }
            $elm.trigger("filmstrip.moved");
        }
    }

    function hideArrows() {
        var a = 0;
        for (i = 0; i < boxesImg.length; i++) {
            a += boxesImg.eq(i)[prop.t]();
        }
        var b = $elm[prop.t]();
        if (a < b) {
            left.css({display: "none"});
            right.css({display: "none"});
        } else {
            left.css({display: ""});
            right.css({display: ""});
        }
    }

    boxesImg.imageSize();
    if (boxesImg.length > 0) {
        var toLoad = boxesImg.length;
        var imgLoaded = 0;
        for (i = 0; i < boxesImg.length; i++) {
            if (boxesImg.eq(i)[prop.t]() > 0) {
                toLoad--;
            } else {
                boxesImg.eq(i).load(function () {
                    imgLoaded++;
                    $elm.trigger("imageLoad", [imgLoaded]);
                });
            }
        }
        $elm.on({
            "imageLoad": function (e, num) {
                if (num == toLoad) {
                    positionBoxes(0);
                }
                hideArrows();
            }
        }).trigger("imageLoad", [imgLoaded]);
    } else {
        positionBoxes(0);
    }
    hideArrows();


    function resize() {
        if (boxesImg.length > 0) {
            positionBoxes(0);
        }
        hideArrows();
    }

    $elm.on({
        updateUi: resize,
        show: resize,
        positionBoxes: function (e, a) {
            if (a) {
                positionBoxes(a.number, a.anim);
            }
        },
        destroy: function () {
            $(window).off({
                mousewheel: scrolled,
                resize: resize,
                DOMMouseScroll: scrolled
            });
        }
    }).addClass("show destroy");


    $(window).on({
        //mousewheel: scrolled,
        resize: resize
        //DOMMouseScroll: scrolled
    });
    function scrolled(e) {
        if ($elm.get(0) == e.target) {
            e.preventDefault();
            if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
                right.trigger("click");
            } else {
                left.trigger("click");
            }
            return false;
        }
        return true;
    }


    $elm.on({
        mouseenter: function () {
            $(this).addClass("scrollableFilmstrip");
        },
        mouseleave: function () {
            $(this).removeClass("scrollableFilmstrip");
        }
    });
    if (options.boxScroll) {
        boxes.onMouse({
            "mouse.start": function (e, pos) {
                if (prop.c == "x") {
                    pos.detectHorizontalDrag(10, 10);
                } else {
                    e.preventDefault();
                }
                pos.data.x = $(this).position().left;
                pos.data.y = $(this).position().top;
                pos.data.diff2 = 0;
            },
            "drag.move": function (e, pos) {
                var diff = pos.diff();
                var move = diff[prop.c] - pos.data.diff2;
                var first = boxes.eq(0);
                var last = boxes.eq(boxes.length - 1);
                if (first.position()[prop.v] + move > 0) {
                    move = 0;
                } else if (last.position()[prop.v] + last[prop.t]() + move < $elm[prop.t]()) {
                    move = 0;
                }
                positionBoxes(move);
                pos.data.diff2 = diff[prop.c];
            }
        });
    }

    right.on({
        mouseenter: function () {
            if (options.hover) {
                func = function () {
                    if (boxes.eq(boxes.length - 1).position()[prop.v] + boxes.eq(boxes.length - 1)[prop.t]()
                        > $(elm)[prop.t]()) {
                        positionBoxes(-(options.move));
                    } else if (options.endless) {
                        var newBox = boxes.eq(0).clone().addClass("clone");
                        $(elm).append(newBox);
                        boxes.eq(0).remove();
                        boxes = $(elm).find(".ui-filmstrip-box");
                        positionBoxes(-(options.move));
                    }
                };
                timer = setInterval(func, options.interval);
            }
        },
        click: function () {
            options.clickMove = $elm[prop.t]() / 2;
            var s = (boxes.eq(boxes.length - 1).position()[prop.v] + boxes.eq(boxes.length - 1)[prop.t]() - $(elm)[prop.t]());
            if (s >= options.clickMove) {
                positionBoxes(-(options.clickMove), true);
            } else if (s == 0) {

            } else if (s > 0) {
                positionBoxes(-(s), true);
            }
        },
        mouseleave: function () {
            if (options.hover) {
                if (timer) {
                    clearInterval(timer);
                }
            }
        }
    });
    left.on({
        mouseenter: function () {
            if (options.hover) {
                func = function () {
                    if (boxes.eq(0).position()[prop.v] < 0) {
                        positionBoxes(options.move);
                    } else if (options.endless) {
                        var newBox = boxes.eq(boxes.length - 1).clone().addClass("clone");
                        var css = {};
                        css[prop.v] = boxes.eq(0).position()[prop.v] - boxes.eq(boxes.length - 1)[prop.t]();
                        newBox.css(css);
                        $(elm).prepend(newBox);
                        boxes.eq(boxes.length - 1).remove();
                        boxes = $(elm).find(".ui-filmstrip-box");
                        positionBoxes(options.move);
                    }
                };
                timer = setInterval(func, options.interval);
            }
        },
        mouseleave: function () {
            if (options.hover) {
                if (timer) {
                    clearInterval(timer);
                }
            }
        },
        click: function () {
            options.clickMove = $elm[prop.t]() / 2;
            var s = boxes.eq(0).position()[prop.v];
            if (s == 0) {

            } else if (-(s) >= options.clickMove) {
                positionBoxes(options.clickMove, true);
            } else {
                positionBoxes(-(s), true);
            }
        }
    });
    setTimeout(function () {
        resize();
    }, 50);
}

$.fn.uiScrollableFilmstrip = function (options) {

    return this.each(function () {
        new ScrollableFilmstrip(this, options);
    });
};


